exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-allgemeinmedizin-tsx": () => import("./../../../src/pages/allgemeinmedizin.tsx" /* webpackChunkName: "component---src-pages-allgemeinmedizin-tsx" */),
  "component---src-pages-arbeitsmedizin-tsx": () => import("./../../../src/pages/arbeitsmedizin.tsx" /* webpackChunkName: "component---src-pages-arbeitsmedizin-tsx" */),
  "component---src-pages-betrieblichebetreuung-tsx": () => import("./../../../src/pages/betrieblichebetreuung.tsx" /* webpackChunkName: "component---src-pages-betrieblichebetreuung-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-gesundheitsfoerderung-tsx": () => import("./../../../src/pages/gesundheitsfoerderung.tsx" /* webpackChunkName: "component---src-pages-gesundheitsfoerderung-tsx" */),
  "component---src-pages-haftungsausschluss-tsx": () => import("./../../../src/pages/haftungsausschluss.tsx" /* webpackChunkName: "component---src-pages-haftungsausschluss-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-qualifikationen-tsx": () => import("./../../../src/pages/qualifikationen.tsx" /* webpackChunkName: "component---src-pages-qualifikationen-tsx" */),
  "component---src-pages-sportbootfuehrerschein-tsx": () => import("./../../../src/pages/sportbootfuehrerschein.tsx" /* webpackChunkName: "component---src-pages-sportbootfuehrerschein-tsx" */),
  "component---src-pages-untersuchungsspektrum-tsx": () => import("./../../../src/pages/untersuchungsspektrum.tsx" /* webpackChunkName: "component---src-pages-untersuchungsspektrum-tsx" */),
  "component---src-pages-verkehrsmedizin-tsx": () => import("./../../../src/pages/verkehrsmedizin.tsx" /* webpackChunkName: "component---src-pages-verkehrsmedizin-tsx" */)
}

